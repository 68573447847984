<template>
  <div class="gaudyTitle">
    <div class="label-title">{{ label }}</div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.gaudyTitle {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  .label-title {
    color: #21252e;
    font-size: 24px;
    font-weight: 500;
    position: relative;
    padding-left: 20px;
  }
  .label-title::before {
    content: "";
    width: 14px;
    height: 20px;
    background: linear-gradient(90deg, #b50413 0%, #a50b18 100%);
    border-radius: 4px 2px 2px 4px;
    position: absolute;
    left: 0;
    top: 7px;
  }
  .label-title::after {
    content: "";
    position: absolute;
    right: -10px;
    bottom: 4px;
    width: 25px;
    height: 6px;
    border-radius: 6px;
    opacity: 1;
    background: linear-gradient(
      90deg,
      rgba(181, 4, 19, 0.2) 0%,
      rgba(141, 8, 19, 0.2) 100%
    );
  }
}
</style>
