<template>
  <div class="menu-layout">
    <div class="menu-list">
      <div class="menu-header">
        <div class="menu-header-body">
          <div class="header-avatar">
            <img src="@/assets/avatar.png" alt="" />
          </div>
          <div class="header-tip">鲁榕润，欢迎学习！</div>
        </div>
      </div>
      <div class="menu-list-body">
        <div class="list-item">
          <div class="list-item-title">
            <svg-icon icon="icon-kecheng-xian" />
            <span>我的课程</span>
          </div>
          <div
            class="list-content"
            :class="{ active: value == 0 }"
            @click="navigationClick('/specialized')"
          >
            专业课程
          </div>
          <div
            class="list-content"
            :class="{ active: value == 1 }"
            @click="navigationClick('/publicDemand')"
          >
            公需课程
          </div>
        </div>
        <div class="list-item">
          <div class="list-item-title">
            <svg-icon icon="icon-kaoshi-xian" />
            <span>在线考试</span>
          </div>
          <div
            class="list-content"
            :class="{ active: value == 2 }"
            @click="navigationClick('/onlineExam')"
          >
            专业课程
          </div>
          <div
            class="list-content"
            :class="{ active: value == 3 }"
            @click="navigationClick('/openOnlineExam')"
          >
            公需课程
          </div>
        </div>
        <div class="list-item">
          <div class="list-item-title">
            <svg-icon icon="icon-fapiao1" />
            <span>电子发票</span>
          </div>
          <div
            class="list-content"
            :class="{ active: value == 4 }"
            @click="navigationClick('/electronicInvoice')"
          >
            电子发票
          </div>
        </div>
      </div>

      <div class="suspension" ref="suspension">
        <div class="suspension-box">
          <div
            class="suspension-left"
            @mouseout="suspensionOut"
            @mouseover="suspensionOver"
          >
            <div class="icon-phone">
              <svg-icon icon="icon-shouji" />
            </div>
            <div class="tip">手机<br />学习</div>
          </div>
          <div class="suspension-right">
            <div class="suspension-top" @click="isIframe = true">
              <svg-icon icon="icon-kefu" />
              <span>在线客服</span>
            </div>
            <div class="line"></div>
            <el-tooltip
              class="item"
              content="客服电话：028-86919928"
              placement="right"
            >
              <div class="suspension-bottom">
                <svg-icon icon="icon-dianhua" />
                <span>电话客服</span>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div
          class="suspension-tip"
          v-show="showZxing"
          :style="{ bottom: zxingBottom + 'px' }"
        >
          <div class="suspension-img" ref="qrcodes"></div>
          <div class="suspension-right">
            <div class="suspension-icon">
              <svg-icon icon="icon-tishi" />
            </div>
            <div class="suspension-title">请使用手机<br />扫码学习</div>
          </div>
        </div>
      </div>
    </div>
    <div class="menu-body">
      <slot></slot>
    </div>
    <div class="iframe-pop" v-if="isIframe">
      <iframe :src="customerChatUrl"></iframe>
    </div>
  </div>
</template>
<script>
import SvgIcon from "@/components/svgIcon.vue";
import QRCode from "qrcodejs2";
import * as storage from "@/utils/storage";
export default {
  components: { SvgIcon },
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      showZxing: false,
      zxingBottom: -106,
      isIframe: false,
      customerChatUrl: "",
    };
  },
  created() {
    this.getGenerateQrcode();
    this.customerChatUrl =
      "http://192.168.1.30:9528/#/?extend=" +
      window.localStorage.getItem("userInfo");
    window.addEventListener("message", this.receiveMessage, false);
  },
  methods: {
    navigationClick(path) {
      if (this.$route.path == path) {
        this.$router.go(0);
      } else {
        this.$router.push({ path: path });
      }
    },
    suspensionOut() {
      if (this.showZxing) {
        this.showZxing = false;
      }
    },
    suspensionOver() {
      if (!this.showZxing) {
        if (
          window.innerHeight -
            this.$refs.suspension.getBoundingClientRect().bottom >
          200
        ) {
          this.zxingBottom = -186;
        } else {
          this.zxingBottom = 95;
        }
        this.showZxing = true;
      }
    },
    getGenerateQrcode() {
      this.$nextTick(() => {
        new QRCode(this.$refs.qrcodes, {
          text: `${
            location.origin
          }/#/authentication?usertoken=${storage.getStore("userToken")}`, // 需要转换为二维码的内容
          width: 160,
          height: 160,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.L,
        });
      });
    },
    receiveMessage(event) {
      if (event.data === "closeParent") {
        this.isIframe = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.iframe-pop {
  position: fixed;
  width: 360px;
  height: 480px;
  bottom: 50px;
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.05),
    0 16px 24px 2px rgba(0, 0, 0, 0.04), 0 8px 10px -5px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  overflow: hidden;
  background: #ffffff;
  z-index: 99;
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
}
.menu-layout {
  width: 100%;
  display: flex;
  padding: 24px 0;
  .menu-list {
    width: 220px;
    margin-right: 20px;
    .menu-header {
      background-color: #ffffff;
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      .menu-header-body {
        text-align: center;
      }
      img {
        width: 64px;
        height: 64px;
        margin: 0 auto;
        border-radius: 100px;
      }
      .header-tip {
        margin-top: 12px;
        font-size: 16px;
      }
    }
    .list-item {
      margin-top: 4px;
      .list-item-title {
        padding: 5px 10px;
        display: flex;
        align-items: center;
        color: #818496;
        font-size: 14px;
        span {
          margin-left: 5px;
        }
      }
      .list-content {
        text-align: center;
        font-size: 16px;
        padding: 16px 0;
        cursor: pointer;
        background-color: #ffffff;
        &.active {
          color: #ba2926;
          font-weight: bold;
          position: relative;
          background: rgba(186, 41, 38, 0.05);
          &::before {
            position: absolute;
            content: "";
            display: block;
            width: 5px;
            bottom: 0;
            background-color: #ba2926;
            top: 0;
            left: 0;
          }
        }
        &:hover {
          color: #ba2926;
          font-weight: bold;
          position: relative;
          background: rgba(186, 41, 38, 0.05);
          &::before {
            position: absolute;
            content: "";
            display: block;
            width: 5px;
            bottom: 0;
            background-color: #ba2926;
            top: 0;
            left: 0;
          }
        }
      }
    }
    .suspension {
      position: relative;
      .suspension-box {
        margin-top: 30px;
        display: flex;
        border-radius: 5px;
        background-color: #ffffff;
        overflow: hidden;
        .suspension-left {
          cursor: pointer;
          padding: 12px 0;
          width: 68px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #ba2926;
          .icon-phone {
            font-size: 18px;
            color: #ffffff;
          }
          .tip {
            color: #ffffff;
            font-size: 14px;
          }
        }
        .suspension-right {
          padding: 10px 34px;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          justify-content: center;
          align-items: center;
          .suspension-top {
            padding-bottom: 10px;
            cursor: pointer;
          }
          .line {
            width: 100%;
            height: 1px;
            background-color: #ebebeb;
          }
          .suspension-bottom {
            padding-top: 10px;
            cursor: pointer;
          }
        }
      }
      .suspension-tip {
        position: absolute;
        padding: 12px;
        background-color: #ba2926;
        display: flex;
        justify-content: center;
        border-radius: 5px;
        color: #ffffff;
        align-items: center;
        z-index: 99;
        .suspension-img {
          width: 160px;
          height: 160px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .suspension-right {
          margin-left: 16px;
          width: 100px;
          text-align: center;
          .suspension-icon {
            font-weight: bold;
            font-size: 20px;
          }
          .suspension-title {
            font-size: 12px;
          }
        }
      }
    }
  }
  .menu-body {
    width: calc(100% - 240px);
    overflow: auto;
  }
}
</style>
